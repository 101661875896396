import { generateClasses } from "@supercoder.dev/bem-classes"
import React, { Component } from "react"

export class Item extends Component {
  handleClick = () => {
    const { id, setSelectedItem } = this.props
    setSelectedItem(id)
  }

  classes = generateClasses.bind(this)({
    name: "item",
    elements: [
      {
        name: "link",
        variants: ["active"],
      },
    ],
  })

  getButtonClasses = () => {
    const { id, selectedItem } = this.props
    const isSelected = id === selectedItem
    if (isSelected) {
      return this.classes.elements.link.variants.active
    }
    return this.classes.elements.link.name
  }

  render() {
    const { label } = this.props
    return (
      <li className={this.classes.name}>
        <button onClick={this.handleClick} className={this.getButtonClasses()}>
          {label}
        </button>
      </li>
    )
  }
}

export default Item
