import { generateClasses } from "@supercoder.dev/bem-classes"
import React, { Component } from "react"
import BaseTabs from "../../Tabs"

export class Header extends Component {
  classes = generateClasses.bind(this)({
    name: "header",
    elements: ["title"],
  })

  render() {
    const { sections, selectedYear, setYear } = this.props
    return (
      <div className={this.classes.name}>
        <h1 className={this.classes.elements.title}>Gallery</h1>
        <BaseTabs
          selectedItem={selectedYear}
          setSelectedItem={setYear}
          parentClass={this.classes.name}
          sections={sections}
        ></BaseTabs>
      </div>
    )
  }
}

export default Header
