import { generateClasses } from "@supercoder.dev/bem-classes"
import React, { Component } from "react"

export class TabsWithContent extends Component {
  className = generateClasses.bind(this)({
    name: "content",
  })

  getIfSectionIsSelected = section => section.id === this.props.selectedItem

  getSelectedSection = () =>
    this.props.sections.find(this.getIfSectionIsSelected)

  render() {
    const selectedSection = this.getSelectedSection()
    return <div className={this.className}>{selectedSection.content}</div>
  }
}

export default TabsWithContent
