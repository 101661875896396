import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import { generateClasses } from "@supercoder.dev/bem-classes"

import List from "./List"
import Header from "./Header"
import TabsWithContent from "../TabsWithContent"

export class Gallery extends Component {
  classes = generateClasses({
    name: "gallery",
  })

  state = {
    year: 2020,
  }

  setYear = year => this.setState({ year })

  getIfImageMatchesYear = year => image => image.frontmatter.year === year

  getImagesByYear = year => {
    const { nodes: images } = this.props.data.allMarkdownRemark
    return images.filter(this.getIfImageMatchesYear(year))
  }

  getImages = () => {
    const { year } = this.state
    return this.getImagesByYear(year)
  }

  findYear = expectedYear => currentYear => currentYear === expectedYear

  getYearFromImage = (years, image) => {
    const { year } = image.frontmatter
    const yearExists = years.find(this.findYear(year))
    if (yearExists) {
      return years
    }
    return [...years, year]
  }

  sortYear = (year1, year2) => year2 - year1

  sortYearsFromNewestToOldest = years => years.sort(this.sortYear)

  getYears = () => {
    const { nodes: images } = this.props.data.allMarkdownRemark
    const years = images.reduce(this.getYearFromImage, [])
    return this.sortYearsFromNewestToOldest(years)
  }

  getSection = year => {
    const images = this.getImagesByYear(year)
    const list = <List images={images} parentClass={this.classes} />
    return {
      id: year,
      label: year,
      content: list,
    }
  }

  getSections = () => {
    const years = this.getYears()
    return years.map(this.getSection)
  }

  render() {
    const { year } = this.state
    const sections = this.getSections()
    return (
      <div className={this.classes}>
        <Header
          parentClass={this.classes}
          sections={sections}
          selectedYear={year}
          setYear={this.setYear}
        />
        <TabsWithContent
          parentClass={this.classes}
          sections={sections}
          selectedItem={year}
        ></TabsWithContent>
      </div>
    )
  }
}

export default class GalleryWithQuery extends Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          {
            allMarkdownRemark(
              filter: { fields: { slug: { regex: "/gallery/" } } }
            ) {
              nodes {
                fields {
                  slug
                }
                html
                frontmatter {
                  title
                  year
                  image {
                    childImageSharp {
                      fluid(quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => <Gallery data={data} />}
      />
    )
  }
}
