import { generateClasses } from "@supercoder.dev/bem-classes"
import React, { Component } from "react"
import Item from "./Item"

export class Tabs extends Component {
  className = generateClasses.bind(this)({
    name: "tabs",
  })

  renderItem = (itemData, index) => {
    const { selectedItem, setSelectedItem } = this.props
    return (
      <Item
        {...itemData}
        key={index}
        parentClass={this.className}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
      />
    )
  }

  renderItems = () => {
    const { sections } = this.props
    return sections.map(this.renderItem)
  }

  render() {
    const items = this.renderItems()
    return <ul className={this.className}>{items}</ul>
  }
}

export default Tabs
