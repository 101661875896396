import { generateClasses } from "@supercoder.dev/bem-classes"
import React, { Component } from "react"
import Item from "./Item"

export class List extends Component {
  classes = generateClasses.bind(this)({
    name: "list",
  })

  renderItem = itemData => (
    <Item {...itemData} key={itemData.fields.slug} parentClass={this.classes} />
  )

  renderItems = () => {
    const { images } = this.props
    return images.map(this.renderItem)
  }

  render() {
    const items = this.renderItems()
    return <ul className={this.classes}>{items}</ul>
  }
}

export default List
