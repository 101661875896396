import { generateClasses } from "@supercoder.dev/bem-classes"
import { Link } from "gatsby"
import Image from "gatsby-image"
import React, { Component } from "react"

export class Item extends Component {
  classes = generateClasses.bind(this)({
    name: "item",
    elements: [
      {
        name: "link",
        elements: [
          {
            name: "image",
          },
        ],
      },
    ],
  })

  render() {
    const {
      fields: { slug },
      frontmatter: { title, image },
    } = this.props
    return (
      <li className={this.classes.name}>
        <Link to={slug} className={this.classes.elements.link.name}>
          {image && image.childImageSharp && (
            <Image
              fluid={image.childImageSharp.fluid}
              alt={title}
              className={this.classes.elements.link.elements.image}
            />
          )}
        </Link>
      </li>
    )
  }
}

export default Item
